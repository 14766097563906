/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    margin-right: 1.5rem;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Roboto Mono', monospace;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: #888888;
  }
  
  .auth {
    display: flex;
    align-items: center;
  }
  
  .user-email {
    margin-right: 1rem;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.9rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    background-color: #333333;
    color: #FFFFFF;
    border: none;
    font-family: 'Roboto Mono', monospace;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #444444;
  }
  
  .btn-secondary {
    background-color: transparent;
    text-decoration: underline;
  }
  
  .btn-secondary:hover {
    background-color: transparent;
    color: #888888;
  }