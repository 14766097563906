/* src/Home.css */
.home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .sign-in {
    background: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 50px 0;
  }
  
  .text-content {
    flex: 1;
  }
  
  h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 0.9rem;
    margin-bottom: 40px;
    max-width: 600px;
  }
  
  .cta-buttons {
    display: flex;
    gap: 20px;
  }
  
  .visual-element {
    flex: 1;
  }
  
  .placeholder-graphic {
    width: 100%;
    height: 400px;
    background: 
      radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px) 0 0 / 20px 20px,
      linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px) 0 0 / 20px 20px;
  }
  
  .footer {
    padding: 20px;
  }
  
  .products ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .footer-links a {
    color: #FFFFFF;
    text-decoration: none;
  }