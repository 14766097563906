/* src/pages/Predict.css */
.predict-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    color: #FFFFFF;
  }
  
  .predict-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group .react-datepicker-wrapper {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #333333;
    color: #FFFFFF;
    border: 1px solid #444444;
    border-radius: 4px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #45a049;
  }
  
  .btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error {
    color: #ff6b6b;
    margin-top: 10px;
  }
  
  .prediction-result {
    margin-top: 20px;
    padding: 15px;
    background-color: #333333;
    border-radius: 4px;
  }
  
  .prediction-result h3 {
    margin-top: 0;
  }

.stock-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;
}

.stock-options li {
  padding: 5px 10px;
  cursor: pointer;
}

.stock-options li:hover {
  background-color: #f0f0f0;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}
.stock-options li {
  padding: 10px; /* Padding for options */
  cursor: pointer; /* Pointer cursor for options */
  color: #333; /* Change this to your desired text color, e.g., dark gray */
  background-color: #fff; /* Keep the background white */
}

.stock-options li:hover {
  background-color: #007bff; /* Change background on hover */
  color: white; /* Change text color on hover */
}
.input-error {
  border: 2px solid red; /* Adjust as necessary */
}
