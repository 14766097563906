/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto+Mono&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #000000;
  color: #FFFFFF;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

button, input, .monospace {
  font-family: 'Roboto Mono', monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  background-color: #333;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #444;
}

.btn-secondary {
  background-color: transparent;
  text-decoration: underline;
}

.dotted-line {
  border-top: 1px dotted rgba(255, 255, 255, 0.2);
  margin: 20px 0;
}