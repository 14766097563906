/* src/styles/Auth.css */
.auth-container {
    max-width: 300px;
    margin: 40px auto;
    padding: 20px;
    background-color: #111;
    border-radius: 8px;
  }
  
  .auth-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .auth-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .auth-container input {
    padding: 10px;
    border: 1px solid #333;
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 4px;
  }
  
  .auth-container .btn {
    padding: 10px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.3s;
  }
  
  .auth-container .btn:hover {
    background-color: #444;
  }
  
  .auth-container .google-btn {
    margin-top: 10px;
    background-color: #4285F4;
  }
  
  .auth-container .google-btn:hover {
    background-color: #357ae8;
  }
  
  .auth-container .error {
    color: #ff4d4d;
    margin-top: 10px;
    text-align: center;
  }
  
  .auth-container p {
    margin-top: 15px;
    text-align: center;
  }
  
  .auth-container a {
    color: #4285F4;
    text-decoration: none;
  }
  
  .auth-container a:hover {
    text-decoration: underline;
  }